import type { Time } from "./time";
import { EmptyTime } from "./EmptyTime";

export class PerformanceTime extends EmptyTime implements Time {
  private readonly startLabel: string | undefined;
  private readonly measureLabel: string;

  constructor(measureLabel: string, startLabel?: string) {
    super();
    this.measureLabel = measureLabel;
    this.startLabel = startLabel;
  }

  get isEmpty(): boolean {
    return this.countMarks == 0 && this.countMeasures == 0;
  }

  get markPerfEntries(): PerformanceEntryList {
    if (!this.startLabel) {
      return [];
    }
    return performance.getEntriesByName(this.startLabel);
  }

  get measurePerfEntries(): PerformanceEntryList {
    return performance.getEntriesByName(this.measureLabel);
  }

  get countMarks(): number {
    return this.markPerfEntries?.length || 0;
  }

  get countMeasures(): number {
    return this.measurePerfEntries?.length || 0;
  }

  reset(): void {
    if (this.countMarks > 0) {
      performance.clearMarks(this.startLabel);
    }
    if (this.countMeasures > 0) {
      performance.clearMeasures(this.measureLabel);
    }
  }

  mark() {
    if (this.startLabel) {
      performance.mark(this.startLabel);
    }
  }

  measure(): number | void {
    if (this.countMarks > 0) {
      performance.measure(this.measureLabel, this.startLabel);
      const performanceEntry = performance.getEntriesByName(this.measureLabel).pop()!;
      const duration: number = performanceEntry.duration || 0;
      performance.clearMarks(this.startLabel);
      return parseFloat(`${duration}`);
    }
  }

  measureOnce(startTime: number, endTime: number = new Date().getTime()): void {
    if (this.countMeasures == 0 && startTime != undefined) {
      try {
        performance.measure(this.measureLabel, {
          start: startTime,
          end: endTime,
        });
      } catch  {
        /*       */
      }
    }
  }

  get timeTotal(): number {
    if (this.countMeasures > 0) {
      return Math.round(
        this.measurePerfEntries
          .map((e) => e.duration)
          .reduce((prev: number, current: number): number => prev + current, 0),
      );
    }
    return -1;
  }

  get timeAverage(): number {
    if (this.countMeasures > 0) {
      return Math.round(this.timeSum / this.countMeasures);
    }
    return -1;
  }

  get timeSum(): number {
    if (this.countMeasures > 0) {
      return this.measurePerfEntries.reduce((e, n) => e + n.duration, 0);
    }
    return -1;
  }

  get navigationStart(): number {
    return Math.round(performance.timeOrigin);
  }
}
