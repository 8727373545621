import { timeSuggestRender } from "../../times/TimeFactory";
import { TrackingLabel } from "../TrackingLabel";
import { SUBMIT_TYPE, submitStore } from "../../store/SubmitStore";

export class SuggestRenderTime extends TrackingLabel {
  get name(): string {
    return "san_SuggestRenderTime";
  }

  get value(): string[] {
    return [timeSuggestRender.timeAverage + ""];
  }

  isTrackable(): boolean {
    return (
      submitStore.isSubmitTypeIn([
        SUBMIT_TYPE.TRENDING_QUERY_ITEM,
        SUBMIT_TYPE.HISTORY_ITEM,
        SUBMIT_TYPE.SUGGEST_ITEM,
        SUBMIT_TYPE.SUBMIT_BUTTON,
        SUBMIT_TYPE.ENTER,
      ]) && !timeSuggestRender.isEmpty
    );
  }
}

export const tlSuggestRenderTime = new SuggestRenderTime();
