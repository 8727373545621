import { derived, type Readable, writable, type Writable, get } from "svelte/store";

export class SearchfieldStore {
  value: Writable<string> = writable("");
  target: Writable<string> = writable("");
  isSuggestOpen: Writable<boolean> = writable(false);
  isFocus: Writable<boolean> = writable(false);

  get isEmpty(): Readable<boolean> {
    return derived(this.value, ($value: string) => $value.trim().length === 0);
  }

  set $value(value: string) {
    this.value.set(value);
  }

  get $value(): string {
    return get(this.value);
  }

  set $target(value: string) {
    this.target.set(value);
  }
}

export const searchfieldStore = new SearchfieldStore();
export const value = searchfieldStore.value;
export const target = searchfieldStore.target;
export const isSuggestOpen = searchfieldStore.isSuggestOpen;
export const isFocus = searchfieldStore.isFocus;
