import { TrackingLabel } from "../TrackingLabel";
import { TL_HIGGINS } from "./higginsLabel";
import { submitStore } from "../../store/SubmitStore";

enum INTERACTION_VALUE {
  INITIAL_SEARCH_BREADCRUMB = "initial_search_breadcrumb",
  INITIAL_SEARCH = "initial_search",
}

export class Interaction extends TrackingLabel<INTERACTION_VALUE[]> {
  readonly name: string = TL_HIGGINS.INTERACTION;
  private _value: INTERACTION_VALUE = INTERACTION_VALUE.INITIAL_SEARCH_BREADCRUMB;

  get value(): INTERACTION_VALUE[] {
    return [this._value];
  }

  /**
 *
 */
  isTrackable(): boolean {
    return submitStore.isBreadcrumbSearch();
  }
}

export const tlInteraction = new Interaction();
