import { TrackingLabel } from "../TrackingLabel";
import { sqrlConsentBanner } from "../../util/SqrlConsentBanner";
import { timeInitSearchField } from "../../times/TimeFactory";

export enum COOKIE_NAME {
  SESSION_START = "o_squirrel_sessionStart",
  SESSION_START_SENDED = "o_squirrel_sessionStart_sended",
}

export class InitialSearchBoxTime extends TrackingLabel {
  private _value: number | undefined;
  private navigationStart: number = timeInitSearchField.navigationStart;

  get name(): string {
    return "wk.san_InitialSearchBoxTime";
  }

  get value(): string[] {
    return [(this._value || -1) + ""];
  }

  isTrackable(): boolean {
    if (!sqrlConsentBanner.isEnabled) {
      return false;
    }
    const sessionStart = Number(window.o_util.cookie.get(COOKIE_NAME.SESSION_START));
    const currentTime = new Date().getTime();
    const sessionTime = currentTime - sessionStart;
    const ttl = 7200000;
    if (sessionTime > ttl) {
      this.navigationStart = tlInitialSearchBoxTime.navigationStart;
      window.o_util.cookie.set(COOKIE_NAME.SESSION_START, `${this.navigationStart}`);
      this.sended = false;
    }

    if (!this.sended && timeInitSearchField.countMeasures > 0) {
      this._value = (timeInitSearchField.measurePerfEntries.pop() as PerformanceEntry).duration;
      this.sended = true;
      return true;
    }
    return false;
  }

  setSessionStart() {
    if (sqrlConsentBanner.isEnabled && !this.sessionStart) {
      window.o_util.cookie.set(COOKIE_NAME.SESSION_START, `${this.navigationStart}`);
      this.sended = false;
    }
  }

  get sessionStart(): number | undefined {
    const sessionStart: string | undefined = window.o_util.cookie.get(COOKIE_NAME.SESSION_START);
    if (!sessionStart) {
      return this.navigationStart;
    }
    return Number(sessionStart);
  }

  private set sended(value: boolean) {
    window.o_util.cookie.set(COOKIE_NAME.SESSION_START_SENDED, `${value}`);
  }

  private get sended(): boolean {
    return window.o_util.cookie.get(COOKIE_NAME.SESSION_START_SENDED) == "true";
  }
}

export const tlInitialSearchBoxTime = new InitialSearchBoxTime();
