export enum SQRL_EVENT {
  /**
 *
 */
  SUGGEST_CLOSE = "squirrel.search.close",

  /**
 *
 */
  SUGGEST_OPEN = "squirrel.search.open",

  /**
 *
 */
  SEARCH_HIDE = "squirrel.search.hide",

  /**
 *
 */
  SEARCH_SHOW = "squirrel.search.show",

  /**
 *
 */
  SEARCH_FOCUS = "squirrel.search.focus",
}
