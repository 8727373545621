export abstract class SqrlGlobal {
  static setLocation(target: string) {
    (window as Window).location = target;
  }

  static scrollTo(element: HTMLElement) {
    const top = element.getBoundingClientRect().top + window.scrollY - 12;
    const behavior = "smooth";
    window.scrollTo({ top, behavior });
  }
}
