import { type Writable, writable } from "svelte/store";
import type { Suggestion } from "../generated";

export class SuggestionStore {
  original: Writable<Suggestion> = writable({} as Suggestion);
  keywords: Writable<Suggestion[]> = writable([]);

  set $original(value: Suggestion) {
    this.original.set(value);
  }

  set $keywords(value: Suggestion[]) {
    this.keywords.set(value);
  }
}

export const suggestionStore = new SuggestionStore();
export const keywords = suggestionStore.keywords;
export const original = suggestionStore.original;
