import { SuggestMethodActionValue } from "./SuggestMethodAction";

export enum TL_HIGGINS {
  INITIAL_SEARCH = "san_InitialSearch",
  INTERACTION = "san_Interaction",
  SUGGEST_LIST = "san_SuggestList",
  SUGGEST_LIST_WORD_COUNT = "san_SuggestListWordCount",
  ACTIVE_SEARCH = "wk.san_ActiveSearch",
  SEARCH_TYPE = "wk.san_SearchType",
  SUGGEST_METHOD = "wk.san_SuggestMethod",
  SUGGEST_POS = "wk.san_SuggestPos",
  SUGGEST_SEARCH_TERM = "wk.san_SuggestSearchTerm",
  SUGGEST_SEARCH_TERM_LENGTH = "wk.san_SuggestSearchTermLength",
  SUGGEST_PERSONALIZED = "wk.san_SuggestPersonalized",
  SEARCH_HISTORY = "wk.san_SearchHistory",
}

export const SEMANTIC_TRACKING_STRING = `${TL_HIGGINS.SUGGEST_METHOD}%22%3A%22${SuggestMethodActionValue.SEMANTIC}`;

export enum SearchHistoryValue {
  EXISTS = "exists",
  FILTER = "filter",
}
