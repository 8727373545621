import { timeEmptyState } from "../../times/TimeFactory";
import { TrackingLabel } from "../TrackingLabel";
import { SUBMIT_TYPE, submitStore } from "../../store/SubmitStore";

export class EmptyStateCount extends TrackingLabel {
  get name(): string {
    return "wk.san_EmptyStateCount";
  }

  get value(): string[] {
    return [timeEmptyState.countMeasures + ""];
  }

  isTrackable(): boolean {
    return submitStore.isSubmitTypeIn([SUBMIT_TYPE.TRENDING_QUERY_ITEM]) && !timeEmptyState.isEmpty;
  }
}

export const tlEmptyStateCount = new EmptyStateCount();
