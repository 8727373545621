import { get } from "svelte/store";
import { TrackingLabel } from "../TrackingLabel";
import { keywords } from "../../store/SuggestionStore";
import { SEMANTIC_TRACKING_STRING } from "./higginsLabel";
import type { DataContainer } from "@otto-ec/tracking-bct";

export enum SuggestMethodActionValue {
  SEMANTIC = "semantic",
}

export class SuggestMethodAction extends TrackingLabel<SuggestMethodActionValue[]> {
  readonly name: string = "san_SuggestMethodAction";
  private _value: SuggestMethodActionValue = SuggestMethodActionValue.SEMANTIC;
  private isTracked: boolean = false;

  get value(): SuggestMethodActionValue[] {
    return [this._value];
  }

  set value(value: SuggestMethodActionValue) {
    this._value = value;
  }

  isTrackable(): boolean {
    return get(keywords).some((keyword) => keyword.target.includes(SEMANTIC_TRACKING_STRING)) &&
      !this.isTracked;

  }

  override get data(): DataContainer {
    const data = super.data;
    if (this.isTrackable()) {
      this.isTracked = true;
    }
    return data;
  }

  /*             */
  setIsTracked(isTracked: boolean) {
    this.isTracked = isTracked;
  }
}

export const tlSuggestMethodAction = new SuggestMethodAction();
