import type { Time } from "./time";

export class EmptyTime implements Time {
  get isEmpty(): boolean {
    return true;
  }

  get countMarks(): number {
    return -1;
  }

  get countMeasures(): number {
    return -1;
  }

  get timeTotal(): number {
    return -1;
  }

  get timeAverage(): number {
    return -1;
  }

  get timeSum(): number {
    return -1;
  }

  reset(): void {
    return;
  }

  measure(): number | void {
    return;
  }

  mark(): void {
    return;
  }

  /*                                                         */
  measureOnce(startTime: number, endTime?: number): void {
    return;
  }

  get measurePerfEntries(): PerformanceEntryList {
    return [];
  }

  get navigationStart(): number {
    return 0;
  }

  [key: string]: number | PerformanceEntryList | boolean | CallableFunction | string | undefined;
}
