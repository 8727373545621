import { TrackingLabel } from "../TrackingLabel";
import { timeSuggest } from "../../times/TimeFactory";
import { SUBMIT_TYPE, submitStore } from "../../store/SubmitStore";

export class SuggestTime extends TrackingLabel {
  readonly name: string = "wk.san_SuggestTime";

  get value(): string[] {
    return [timeSuggest.timeTotal + ""];
  }

  isTrackable(): boolean {
    return submitStore.isSubmitTypeIn([SUBMIT_TYPE.SUGGEST_ITEM]);
  }
}

export const tlSuggestTime = new SuggestTime();
