import type { Time } from "./time";
import { PerformanceTime } from "./PerformanceTime";
import { EmptyTime } from "./EmptyTime";

export enum MARK {
  EMPTY_STATE = "empty_state_time_start",
  SUGGEST = "suggest_time_start",
  SUGGEST_RENDER = "suggest_render_time_start",
}

export enum MEASURE {
  EMPTY_STATE = "empty_state_time",
  SUGGEST = "suggest_time",
  SUGGEST_RENDER = "suggest_render_time",
  SEARCH_FIELD_INIT = "initial_search_box_time",
}

export abstract class TimeFactory {
  static get emptyStateTime(): Time {
    return TimeFactory.createTime(MEASURE.EMPTY_STATE, MARK.EMPTY_STATE);
  }

  static get suggestTime(): Time {
    return TimeFactory.createTime(MEASURE.SUGGEST, MARK.SUGGEST);
  }

  static get suggestRenderTime(): Time {
    return TimeFactory.createTime(MEASURE.SUGGEST_RENDER, MARK.SUGGEST_RENDER);
  }

  static get initSearchFieldTime(): Time {
    return TimeFactory.createTime(MEASURE.SEARCH_FIELD_INIT);
  }

  private static get isValid(): boolean {
    return (
      !!performance && !!performance.measure && !!performance.mark && !!performance.getEntriesByName
    );
  }

  static createTime(measureLabel: string, startLabel?: string): Time {
    if (TimeFactory.isValid) {
      return new PerformanceTime(measureLabel, startLabel);
    }
    return new EmptyTime();
  }
}

export const timeEmptyState = TimeFactory.emptyStateTime;
export const timeSuggest = TimeFactory.suggestTime;
export const timeSuggestRender = TimeFactory.suggestRenderTime;
export const timeInitSearchField = TimeFactory.initSearchFieldTime;
