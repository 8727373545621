import type { DataContainer } from "@otto-ec/tracking-bct";

export abstract class TrackingLabel<ValueType = string[]> {
  abstract readonly name: string;
  abstract readonly value: ValueType;

  abstract isTrackable(): boolean;

  get data(): DataContainer {
    if (!this.isTrackable()) {
      return {};
    }
    return {
      [this.name]: this.value,
    } as DataContainer;
  }
}
