import type { DataContainer } from "@otto-ec/tracking-bct";
import type { TrackingData } from "../types/tracking";

export class SqrlUrl {
  private hashList: { key: string; value: string }[];
  readonly trackingData: TrackingData;
  private url: URL;

  constructor(action: string) {
    this.url = new URL(action);
    this.hashList = this.createHashList();
    this.trackingData = this.createTrackingData();
  }

  get urlWithoutTracking(): string {
    this.url.host = window.location.host;
    this.url.protocol = window.location.protocol;
    this.url.hash = this.hashList
      .filter((hashItem: { key: string; value: string }) => hashItem.key != "t")
      .map((hashItem: { key: string; value: string }) => `${hashItem.key}=${hashItem.value}`)
      .join("&");
    return this.url.href;
  }

  urlWithRef(trackingData: TrackingData): string {
    return this.url.href + this.getTRef(trackingData);
  }

  getTRef(trackingData: TrackingData): string {
    return `#t=${encodeURIComponent(JSON.stringify(trackingData))}`;
  }

  private createHashList(): { key: string; value: string }[] {
    return this.url.hash
      .split(/[#&]/)
      .filter((hash: string) => hash.length > 0)
      .map((hash: string) => {
        return { key: hash.split("=")[0], value: hash.split("=")[1] };
      });
  }

  private createTrackingData(): DataContainer {
    const trackingHash: { key: string; value: string }[] = this.hashList.filter(
      (hashItem: { key: string; value: string }) => hashItem.key == "t",
    );
    if (trackingHash.length <= 0) {
      return {};
    }
    const trackingValues: string = decodeURIComponent(trackingHash[0].value);
    const trackingDataJson: { [k: string]: string } = JSON.parse(trackingValues);
    const data: DataContainer = {};
    Object.keys(trackingDataJson).forEach((key) => {
      const value: unknown = trackingDataJson[key];
      if (!(value instanceof Array)) {
        data[key] = [value as string];
      } else {
        data[key] = value as string[];
      }
    });
    return data;
  }
}
