import { get, type Writable, writable } from "svelte/store";

/**
 *
 *
 */
export class IndexStore {
  activeIndex: Writable<number> = writable(-1);
  maxIndex: Writable<number> = writable(0);
  isSuggestTypeFrozen: Writable<boolean> = writable(false);

  updateMaxIndex(length: number) {
    this.maxIndex.set(length);
  }

  next() {
    this.activeIndex.update(($activeIndex: number) => {
      const $maxIndex: number = get(this.maxIndex);
      this.isSuggestTypeFrozen.set(true);
      if ($activeIndex === $maxIndex - 1) {
        return -1;
      } else {
        return ($activeIndex + 1) % $maxIndex;
      }
    });
  }

  previous() {
    this.activeIndex.update(($activeIndex: number) => {
      const $maxIndex: number = get(this.maxIndex);
      this.isSuggestTypeFrozen.set(true);
      if ($activeIndex === -1) {
        return $maxIndex - 1;
      } else if ($activeIndex === 0) {
        return -1;
      } else {
        return ($activeIndex - 1) % ($maxIndex + 1);
      }
    });
  }

  reset() {
    this.activeIndex.set(-1);
    this.isSuggestTypeFrozen.set(false);
  }
}

export const indexStore = new IndexStore();
export const activeIndex = indexStore.activeIndex;
export const maxIndex = indexStore.maxIndex;
export const next = indexStore.next;
export const previous = indexStore.previous;
export const isSuggestTypeFrozen = indexStore.isSuggestTypeFrozen;
