import { timeEmptyState } from "../../times/TimeFactory";
import { TrackingLabel } from "../TrackingLabel";
import { SUBMIT_TYPE, submitStore } from "../../store/SubmitStore";

export class EmptyStateTime extends TrackingLabel {
  get name(): string {
    return "wk.san_EmptyStateTime";
  }

  get value(): string[] {
    return [timeEmptyState.timeTotal + ""];
  }

  isTrackable(): boolean {
    return submitStore.isSubmitTypeIn([SUBMIT_TYPE.TRENDING_QUERY_ITEM]) && !timeEmptyState.isEmpty;
  }
}

export const tlEmptyStateTime = new EmptyStateTime();
