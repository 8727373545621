import {SUBMIT_TYPE, submitStore} from "../../store/SubmitStore";
import {TrackingLabel} from "../TrackingLabel";
import {TL_HIGGINS} from "./higginsLabel";
import {SEARCH_TYPE} from "../../constants/searchType";

export class SearchType extends TrackingLabel {
  readonly name: string = TL_HIGGINS.SEARCH_TYPE;

  get value(): string[] {
    if (submitStore.isSubmitTypeIn([SUBMIT_TYPE.HISTORY_ITEM])) {
      return [SEARCH_TYPE.HISTORY];
    } else if (submitStore.isSubmitTypeIn([SUBMIT_TYPE.TRENDING_QUERY_ITEM])) {
      return [SEARCH_TYPE.TRENDING_QUERY];
    } else if (submitStore.isSubmitTypeIn([SUBMIT_TYPE.SUGGEST_ITEM])) {
      return [SEARCH_TYPE.SUGGEST];
    } else {
      return [SEARCH_TYPE.SEARCH];
    }
  }

  isTrackable(): boolean {
    return true;
  }
}

export const tlSearchType = new SearchType();
