import { STORAGE } from "../constants/storage";
import { sqrlConsentBanner } from "./SqrlConsentBanner";

export class SqrlStorage {
  getLocalStorage(): Storage {
    return window.localStorage;
  }

  private shouldUseHistory() {
    return sqrlConsentBanner.isEnabled && this.getLocalStorage();
  }

  setItem(name: STORAGE, value: string) {
    if (this.shouldUseHistory()) {
      this.getLocalStorage().setItem(name, value);
    }
  }

  removeItem(name: STORAGE) {
    if (this.shouldUseHistory()) {
      this.getLocalStorage().removeItem(name);
    }
  }

  getItem(name: STORAGE): string | null {
    if (this.shouldUseHistory()) {
      return this.getLocalStorage().getItem(name);
    }
    return null;
  }
}

export const sqrlStorage = new SqrlStorage();
